import React, { useEffect, useState } from "react";
import styles from "../flight-status-route-detail/flight-status-route-detail.module.scss";
import FlightStatus from "..";
import TGIcon from "../../../../../../shared/tg-icon";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import { RootState } from "../../../../slice/RootReducer";
import { useDispatch, useSelector } from "react-redux";
import { displayDateFormat } from "../../../../../../utils/helper";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { AirportData } from "../../../../models/models";
import {
  flightStatusByNumberRequest,
  flightStatusNumberClear,
} from "../../../../slice/flightStatusSlice";
import { Card, Placeholder } from "react-bootstrap";

interface Props {
  type?: any;
}

const FlightStatusUpcomingTripsDetails: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const flightNumberDetailSelector = useSelector(
    (state: RootState) => state?.osciReducer?.flightStatus.flightNumberDetail
  );

  const isFlightStatusError = useSelector(
    (state: RootState) => state?.osciReducer?.flightStatus.isError
  );

  const airportData: AirportData | any = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget.airportData
  );

  const pnrDataSelector: any = useSelector(
    (state: RootState) => state?.osciReducer?.pnr.pnrDetails
  );

  const { error, isLoading }: any = useSelector(
    (state: RootState) => state?.osciReducer?.pnr
  );
  const [flightStatusData, setFlightStatusData] = useState<any>([]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (pnrDataSelector?.groupingInfo?.length > 0) {
      const result = pnrDataSelector?.groupingInfo?.map((x: any) => {
        return {
          ...x,
          isExpanded: false,
        };
      });
      setFlightStatusData(result);
    } else {
      setFlightStatusData([]);
    }
  }, [pnrDataSelector?.groupingInfo]);

  useEffect(() => {
    if (flightNumberDetailSelector?.flightList) {
      const index = flightStatusData?.findIndex((x: any) => x?.isExpanded);
      const temp = [...flightStatusData];
      temp[index] = {
        ...temp[index],
        arrivalStatus: flightNumberDetailSelector?.flightList?.arrivalStatus,
        departureStatus:
          flightNumberDetailSelector?.flightList?.departureStatus,
        currentStatus: flightNumberDetailSelector?.flightList?.status,
      };
      setFlightStatusData(temp);
    }
  }, [flightNumberDetailSelector?.flightList]);

  const screenSize = useScreenSize().deviceSize.width;

  const handleDisplayFlightStatus = (currentIndex: number, info: any) => {
    const temp = [...flightStatusData];
    dispatch(flightStatusNumberClear());
    if (
      !flightStatusData[currentIndex]?.isExpanded &&
      (!flightStatusData?.[currentIndex]?.departureStatus ||
        !flightStatusData[currentIndex]?.arrivalStatus)
    ) {
      dispatch(
        flightStatusByNumberRequest({
          date: moment(info?.groupedFlightList[0]?.departureDate).format(
            "DDMMYY"
          ),
          // date: "060824",
          flightNumber: info?.groupedFlightList[0]?.flightNumber.split(" ")[1],
        })
      );
    }
    const resultArr = temp?.map((z: any, y: any) => {
      return {
        ...z,
        isExpanded:
          y !== currentIndex
            ? false
            : !flightStatusData[currentIndex]?.isExpanded,
      };
    });
    setFlightStatusData(resultArr);
  };

  const renderAirportDetail = (code: string) => {
    const filteredObj = airportData?.filter(
      (x: any) => x.airportCode === code
    )?.[0];
    return filteredObj;
  };

  const renderStatus = (cS: string) => {
    switch (cS) {
      case "Arrived":
      case "On Time":
      case "Scheduled":
        document.documentElement.style.setProperty(
          "--status-text-color",
          "#006723"
        );
        document.documentElement.style.setProperty(
          "--status-bg-color",
          "#D0F8DE"
        );
        return cS;
      case "Delayed":
        document.documentElement.style.setProperty(
          "--status-text-color",
          "#CC9405"
        );
        document.documentElement.style.setProperty(
          "--status-bg-color",
          "#FFF5DC"
        );
        return cS;
      case "Cancelled":
        document.documentElement.style.setProperty(
          "--status-text-color",
          "#FF2E57"
        );
        document.documentElement.style.setProperty(
          "--status-bg-color",
          "#FFE5EA"
        );
        return cS;
    }
  };

  const renderExpandableLink = (u: any, v: number) => {
    return (
      <div className={styles["flight-status-flight-route-sidebar-status"]}>
        <div onClick={() => handleDisplayFlightStatus(v, u)}>
          {u?.isExpanded ? (
            <>
              {t("label_hideflightstatus")}{" "}
              <span>
                <TGIcon
                  icon="summary-redemption-up-arrow-icon"
                  size="20px"
                  fillColor=""
                />
              </span>
            </>
          ) : (
            <>
              {t("label_showflightstatus")}{" "}
              <span>
                <TGIcon
                  icon="summary-redemption-down-arrow-icon"
                  size="20px"
                  fillColor=""
                />
              </span>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderStatusSection = (obj: any, otherObj: any, isArrival: boolean) => {
    return (
      <>
        <div
          className={`${styles["flight-status-more-details-stepper"]} d-flex flex-column align-items-center`}
        >
          <div
            className={`${
              isArrival
                ? styles["flight-status-stepper-line-arrival"]
                : styles["flight-status-stepper-line-departure"]
            } line`}
          ></div>
          {obj?.code ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <circle
                cx="8"
                cy="8"
                r="6"
                stroke={isArrival ? "#DFE0EB" : "#6A6D87"}
                strokeWidth="1.6"
              />
            </svg>
          ) : (
            <svg width="18" height="0"></svg>
          )}
          <div
            className={`${
              isArrival
                ? styles["flight-status-stepper-line-arrival"]
                : styles["flight-status-stepper-line-departure"]
            } line h-100`}
          ></div>
        </div>
        <div
          className={`${styles["flight-status-content-wrapper"]} d-flex w-100`}
        >
          <div
            className={`${styles["flight-status-content"]} d-flex flex-column`}
          >
            <div
              className={
                isArrival
                  ? styles["flight-status-more-details-message-arrival"]
                  : styles["flight-status-more-details-message"]
              }
            >
              {obj.message}
            </div>
            <div className={styles["flight-status-more-details-time"]}>
              {obj?.time &&
                `${obj.time?.substring(0, 2)}:${obj.time?.substring(4, 2)}`}
            </div>
          </div>
          <div
            className={` ${styles["flight-status-airport-content"]} d-flex flex-column`}
          >
            <div
              className={
                isArrival
                  ? styles["flight-status-more-details-airport-data-arrival"]
                  : styles["flight-status-more-details-airport-data"]
              }
            >
              {renderAirportDetail(
                isArrival
                  ? otherObj?.groupedFlightList[0]?.arrivalCode
                  : otherObj.groupedFlightList[0]?.departureCode
              )?.cityName?.replace(" ", "") +
                ", " +
                renderAirportDetail(
                  isArrival
                    ? otherObj?.groupedFlightList[0]?.arrivalCode
                    : otherObj?.groupedFlightList[0]?.departureCode
                )?.countryName}
            </div>
            <div className={styles["flight-status-more-details-time"]}>
              {
                renderAirportDetail(
                  isArrival
                    ? otherObj?.groupedFlightList[0]?.arrivalCode
                    : otherObj?.groupedFlightList[0]?.departureCode
                )?.airportName
              }
            </div>
            <div className={`${styles["flight-status-more-details-time"]}`}>
              {(isArrival
                ? otherObj?.groupedFlightList[0]?.arrivalTerminal
                : otherObj?.groupedFlightList[0]?.departureTerminal) &&
                `Terminal ${
                  isArrival
                    ? otherObj?.groupedFlightList[0]?.arrivalTerminal
                    : otherObj?.groupedFlightList[0]?.departureTerminal
                }`}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      className={`${styles["flight-status-container"]} global-content-padding`}
    >
      <div className={styles["flight-status-upcoming-booking-container"]}>
        <h4 className={`${styles["city-detail"]} mb-0`}>
          {t("label_upcomingtrip_header")}
        </h4>
      </div>
      <div className="d-flex flex-column mb-4">
        {flightStatusData?.length > 0 && !error
          ? flightStatusData?.map((i: any, index: number) => (
              <>
                <div className={styles["flight-status-flight-route-container"]}>
                  <div
                    className={`${styles["flight-status-flight-route-card"]} ${
                      !i?.isExpanded &&
                      styles["flight-status-flight-route-card-bottom-border"]
                    }`}
                  >
                    <div
                      className={styles["flight-status-flight-route-sidebar"]}
                    >
                      <div
                        className={
                          styles["flight-status-flight-route-sidebar-logo"]
                        }
                      >
                        <span className="me-1">
                          <TGIcon
                            icon="thai-header-logo"
                            fillColor=""
                            size="20px"
                          />
                        </span>
                        <TGIcon
                          icon="thai-header-text"
                          fillColor=""
                          size="20"
                          viewBox="0 0 82 18"
                        />
                      </div>
                      <div
                        className={
                          styles["flight-status-flight-route-sidebar-flightnum"]
                        }
                      >
                        {t("label_book_widget_tg")}{" "}
                        {i?.groupedFlightList[0]?.flightNumber.split(" ")[1]}
                      </div>
                      <div
                        className={
                          styles["flight-status-flight-route-sidebar-date"]
                        }
                      >
                        <div>
                          {displayDateFormat(
                            moment(i?.groupedFlightList[0]?.departureDate)
                          )}
                        </div>
                        <div>
                          {i?.groupedFlightList[0]?.tripDuration
                            ? i?.groupedFlightList[0]?.tripDuration
                            : ""}
                        </div>
                        <div>{i?.groupedFlightList[0]?.airCarrier}</div>
                      </div>
                      {screenSize > 767 && renderExpandableLink(i, index)}
                    </div>
                    {screenSize < 768 && (
                      <div className={styles["flight-status-seperation"]}></div>
                    )}
                    <div
                      className={
                        styles["flight-status-flight-duration-main-section"]
                      }
                    >
                      <div
                        className={
                          styles["flight-status-flight-duration-sub-section"]
                        }
                      >
                        <div
                          className={
                            styles["flight-status-flight-duration-info-section"]
                          }
                        >
                          <div
                            className={styles["flight-status-schedule-label"]}
                          >
                            {/* {t("label_scheduled")} NA:NA */}
                          </div>
                          <div>
                            <div
                              className={styles["flight-status-actual-label"]}
                            >
                              {t("label_scheduled")}
                            </div>
                            <div
                              className={styles["flight-status-schedule-time"]}
                            >
                              {i?.groupedFlightList[0]?.departureTime?.substring(
                                0,
                                2
                              )}
                              :
                              {i?.groupedFlightList[0]?.departureTime?.substring(
                                3,
                                5
                              )}
                            </div>
                          </div>
                          <div>
                            <div
                              className={styles["flight-status-airport-code"]}
                            >
                              {i?.groupedFlightList[0]?.departureCode}
                            </div>
                            <div className={styles["flight-status-city-name"]}>
                              {
                                renderAirportDetail(
                                  i?.groupedFlightList[0]?.departureCode
                                )?.cityName
                              }
                            </div>
                          </div>
                          <div>
                            <div
                              className={styles["flight-status-airport-name"]}
                            >
                              {
                                renderAirportDetail(
                                  i?.groupedFlightList[0]?.departureCode
                                )?.airportName
                              }
                            </div>
                            <div
                              className={`${
                                styles["flight-status-terminal-number"]
                              } ${
                                !i?.groupedFlightList[0]?.departureTerminal &&
                                styles["flight-status-no-terminal"]
                              }`}
                            >
                              {i?.groupedFlightList[0]?.departureTerminal
                                ? `${t("label_redemption_termainal")} ${
                                    i?.groupedFlightList[0]?.departureTerminal
                                  }`
                                : "NA"}
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            styles["flight-status-flight-duration-section"]
                          }
                        >
                          <div>
                            {i?.groupedFlightList[0]?.tripDuration
                              ? i?.groupedFlightList[0]?.tripDuration
                              : ""}
                          </div>
                          <div>
                            <div></div>
                            <div>
                              <TGIcon icon="plane-icon" fillColor="" size="" />
                            </div>
                            <div></div>
                          </div>
                          {i?.groupedFlightList[0]?.numberOfStops === 0 && (
                            <div>{t("label_nonstop")}</div>
                          )}
                          {i?.groupedFlightList[0]?.numberOfStops !== 0 && (
                            <div>{i?.groupedFlightList[0]?.numberOfStops}</div>
                          )}
                        </div>
                        <div
                          className={
                            styles["flight-status-flight-duration-info-section"]
                          }
                        >
                          <div
                            className={styles["flight-status-schedule-label"]}
                          ></div>
                          <div>
                            <div
                              className={styles["flight-status-actual-label"]}
                            >
                              {t("label_scheduled")}
                            </div>
                            <div
                              className={styles["flight-status-schedule-time"]}
                            >
                              {i?.groupedFlightList[0]?.arrivalTime?.substring(
                                0,
                                2
                              )}
                              :
                              {i?.groupedFlightList[0]?.arrivalTime?.substring(
                                3,
                                5
                              )}
                            </div>
                          </div>
                          <div>
                            <div
                              className={styles["flight-status-airport-code"]}
                            >
                              {i?.groupedFlightList[0]?.arrivalCode}
                            </div>
                            <div className={styles["flight-status-city-name"]}>
                              {
                                renderAirportDetail(
                                  i?.groupedFlightList[0]?.arrivalCode
                                )?.cityName
                              }
                            </div>
                          </div>
                          <div>
                            <div
                              className={styles["flight-status-airport-name"]}
                            >
                              {
                                renderAirportDetail(
                                  i?.groupedFlightList[0]?.arrivalCode
                                )?.airportName
                              }
                            </div>
                            <div
                              className={`${
                                styles["flight-status-terminal-number"]
                              } ${
                                !i?.groupedFlightList[0]?.arrivalTerminal &&
                                styles["flight-status-no-terminal"]
                              }`}
                            >
                              {i?.groupedFlightList[0]?.arrivalTerminal
                                ? `${t("label_redemption_termainal")} ${
                                    i?.groupedFlightList[0]?.arrivalTerminal
                                  }`
                                : "NA"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {screenSize < 768 && renderExpandableLink(i, index)}
                  </div>
                </div>
                {i?.isExpanded && (
                  <div
                    className={styles["flight-status-flight-number-details"]}
                  >
                    <div
                      className={
                        styles["flight-status-flight-route-more-details"]
                      }
                    >
                      {i?.departureStatus ? (
                        <>
                          {!(
                            i?.departureStatus?.length === 0 &&
                            i?.arrivalStatus?.length === 0
                          ) && (
                            <div className="d-flex gap-3 mb-4">
                              <div
                                className={
                                  styles["flight-status-more-details-header"]
                                }
                              >
                                {t("label_currentflightstatus")}
                              </div>
                              <div
                                className={
                                  styles["flight-status-more-details-status"]
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="8"
                                  height="8"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                >
                                  <circle cx="4" cy="4" r="4" fill="#CC9405" />
                                </svg>
                                {renderStatus(i?.currentStatus)}
                              </div>
                            </div>
                          )}
                          {i?.departureStatus?.length === 0 &&
                            i?.arrivalStatus?.length === 0 && (
                              <div
                                className={
                                  styles["no-result-icon-status-wrapper"]
                                }
                              >
                                <div className={styles["no-result-icon"]}>
                                  <TGIcon
                                    icon="no-flight-status"
                                    fillColor=""
                                    size=""
                                  />
                                </div>
                                <div
                                  className={
                                    styles["flight-status-no-result-msg"]
                                  }
                                >
                                  {t("label_flight_status_not_available")}
                                </div>
                                <div
                                  className={
                                    styles["flight-status-no-result-desc"]
                                  }
                                >
                                  {t("label_flight_status_update")}
                                </div>
                              </div>
                            )}
                          {i?.departureStatus?.map((z: any, n: boolean) => (
                            <div
                              className={`${styles["flight-status-more-details-wrapper"]} d-flex w-100`}
                              key={`departure${n}`}
                            >
                              {renderStatusSection(z, i, false)}
                            </div>
                          ))}
                          {i?.arrivalStatus?.map((z: any, cI: number) => (
                            <div
                              className={`${styles["flight-status-more-details-wrapper"]} d-flex w-100`}
                              key={`arrival${cI}`}
                            >
                              {renderStatusSection(z, i, true)}
                            </div>
                          ))}
                        </>
                      ) : isFlightStatusError ? (
                        <div className={styles["flight-status-no-result"]}>
                          {t("label_flight_status_error")}
                        </div>
                      ) : (
                        [1, 2]?.map((x: any) => (
                          <div className={styles["skelton-loader"]}>
                            <div className="mb-3">
                              <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={6} />
                              </Placeholder>
                              <Placeholder as={Card.Text} animation="glow">
                                <Placeholder xs={4} />{" "}
                              </Placeholder>
                            </div>
                            <div>
                              <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={6} />
                              </Placeholder>
                              <Placeholder as={Card.Text} animation="glow">
                                <Placeholder xs={7} /> <Placeholder xs={4} />
                              </Placeholder>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                )}
              </>
            ))
          : !isLoading && (
              <div className={styles["no-result-icon-wrapper"]}>
                <div className={styles["no-result-icon"]}>
                  <TGIcon icon="no-flight-found" fillColor="" size="" />
                </div>
                <div className={styles["flight-status-no-result-msg"]}>
                  {t("label_flight_status_error")}
                </div>
                <div className={styles["flight-status-no-result-desc"]}>
                  {t("label_flight_status_search_criteria")}
                </div>
              </div>
            )}
      </div>
    </div>
  );
};

export default FlightStatusUpcomingTripsDetails;
