import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import { AirportData, FlightStatusProps } from "../../../models/models";
import styles from "./flight-status.module.scss";
import { FlightStatusType } from "../../../../../utils/enum-helper";
import FlightNumber from "./flight-number";
import FlightRoute from "./flight-route";
import { airportDataRequest } from "../../../slice/bookingWidgetSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import FlightStatusUpcomingTrips from "./flight-status-upcoming-trips";
import {
  getTripDataFailure,
  getTripDataSuccess,
  tripDataRequest,
} from "../../../slice/tripSlice";
import { config } from "../../../../../config/global";
import FlightStatusRecentSearch from "./flight-status-recent-search";
import { getLocalStorageData } from "../../../../../utils/helper";
import isAuthenticate from "../../../../../utils/hook/isAuthenticate";

const FlightStatus: React.FC<FlightStatusProps> = (
  props: FlightStatusProps
) => {
  const isAuthenticated = isAuthenticate();
  const { t, i18n } = useTranslation();
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 768;
  const dispatch = useDispatch();
  const MANAGE_BOOKING_API_URL = config.TEAMSITE_UPCOMING_TRIPS_API;
  const airportData: AirportData | null = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget.airportData
  );
  const savedTab = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget?.tabIndex
  );
  const tripData: any = useSelector(
    (state: RootState) => state?.osciReducer?.trips?.upcomingTrips
  );

  const [recentSearchFlightStatusData, setRecentSearchFlightStatusData] =
    useState<any>([]);
  const flightStatusTabs: any[] = [
    {
      value: FlightStatusType.FlightNumber,
      label: t("label_flight_status_flight_number"),
    },
    {
      value: FlightStatusType.Route,
      label: t("label_flight_status_flight_route"),
    },
  ];

  const [selectedItem, setSelectedItem] = useState<string>(
    FlightStatusType.FlightNumber
  );

  useEffect(() => {
    if (props.activeTab || savedTab?.[1]) {
      setSelectedItem(props.activeTab || savedTab?.[1]);
    }
  }, [props.activeTab]);

  useEffect(() => {
    if (!airportData) {
      dispatch(airportDataRequest(i18n.language?.split("-")?.[0]));
    }
  }, [airportData]);

  useEffect(() => {
    const getRecentFlightStatusData = () => {
      const flightStatusData = getLocalStorageData("recentSearchFlight");

      if (flightStatusData) {
        let formatData = JSON.parse(flightStatusData);
        setRecentSearchFlightStatusData(formatData);
      } else {
        setRecentSearchFlightStatusData([]);
      }
    };

    getRecentFlightStatusData();
  }, []);

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === "recentSearchFlight") {
        setRecentSearchFlightStatusData(
          event.newValue ? JSON.parse(event.newValue) : null
        );
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(tripDataRequest());
    }
  }, [MANAGE_BOOKING_API_URL, dispatch, isAuthenticated]);

  const handleTabSelection = (selectedTab: any) => {
    setSelectedItem(selectedTab);
  };

  const renderTabContent = () => {
    switch (selectedItem) {
      case "Flight Number":
        return (
          <FlightNumber
            type={FlightStatusType.FlightNumber}
            currentTabIndex={props.currentTabIndex}
            handleCloseSidebar={props?.handleCloseSidebar}
          />
        );
      case "Route":
        return (
          <FlightRoute
            type={FlightStatusType.Route}
            handleCloseSidebar={props?.handleCloseSidebar}
          />
        );

      default:
        return null;
    }
  };

  const renderDynamicTabContent = () => (
    <div className={styles["flight-status-container"]}>
      <div>
        {flightStatusTabs.map((item: any) => (
          <button
            key={item}
            className={`${styles.item} ${
              selectedItem === item.value ? `${styles.selected}` : ""
            }`}
            onClick={() => handleTabSelection(item.value)}
            title={item.value}
            aria-label={`Flight Status Tab ${item.value}`}
          >
            {item.label}
          </button>
        ))}
      </div>
      {isDesktopView && <div></div>}
    </div>
  );
  return (
    <>
      <div className={styles["flight-status-main-container"]}>
        <div>{renderDynamicTabContent()}</div>
        <div>{renderTabContent()}</div>
      </div>
      {!props?.isHidden && recentSearchFlightStatusData.length > 0 && (
        <FlightStatusRecentSearch
          formattedSearchFlightData={recentSearchFlightStatusData}
          setFormattedSearchFlightData={setRecentSearchFlightStatusData}
          setSelectedItem={setSelectedItem}
        />
      )}
      {!props?.isHidden && tripData?.pnrDetails?.length > 0 && (
        <FlightStatusUpcomingTrips isMiniProfile={false} page="FS" />
      )}
      {!props?.isHidden && tripData?.pnrDetails?.length === 0 && (
        <div className={styles["flight-status-no-upcoming-trips"]}>
          <img src="/assets/noUpcomingTrips.svg" alt="No Upcoming Trips" />
          <p className={styles["flight-status-no-trips"]}>
            {t("label_no_upcoming_trips")}
          </p>
        </div>
      )}
    </>
  );
};

export default FlightStatus;
