import React, { useRef, useState } from "react";
import styles from "./flight-status-recent-search.module.scss";
import {
  reverseJsonArray,
  setLocalStorageData,
} from "../../../../../../utils/helper";
import { useDispatch } from "react-redux";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import { useTranslation } from "react-i18next";
import TGIcon from "../../../../../../shared/tg-icon";
import Slider from "react-slick";
import moment from "moment";
import { updateFlightSelectedCardData } from "../../../../slice/flightStatusSlice";

interface Props {
  formattedSearchFlightData?: any;
  setFormattedSearchFlightData?: Function;
  setSelectedItem?: any;
}

const FlightStatusRecentSearch: React.FC<Props> = (props: Props) => {
  const sliderRef = useRef<Slider>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const totalSlides = props.formattedSearchFlightData.length;
  const screenSize = useScreenSize().deviceSize.width;
  const isDesktopView = screenSize > 767;
  const reverseSearchData = reverseJsonArray(props.formattedSearchFlightData);

  let slidesToShow = 3;

  const elementRef = useRef<any>(null);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: screenSize < 768 ? 1.1 : 3,
    slidesToScroll: isDesktopView ? 2 : 1,
    arrows: false,
    autoplay: false,
    initialSlide: 0,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex);
    },
    customPaging: (i: number) => (
      <>
        {isDesktopView && (
          <div
            className={styles.customDot}
            onClick={() => {
              sliderRef.current?.slickGoTo(i);
            }}
          >
            {elementRef?.current?.childNodes?.length - 1 === i &&
            currentSlide >= totalSlides - slidesToShow ? (
              <svg
                width="20"
                height="6"
                viewBox="0 0 20 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="6" rx="3" fill="#684B9B" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                viewBox="0 0 6 6"
                fill="none"
              >
                <circle opacity="0.5" cx="3" cy="3" r="3" fill="#CACAD9" />
              </svg>
            )}
          </div>
        )}
        {!isDesktopView && (
          <div
            className={styles.customDot}
            onClick={() => {
              sliderRef.current?.slickGoTo(i);
            }}
          >
            {elementRef?.current?.childNodes?.length - 1 === i &&
            Math.ceil(currentSlide) === i ? (
              <svg
                width="20"
                height="6"
                viewBox="0 0 20 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="6" rx="3" fill="#684B9B" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                viewBox="0 0 6 6"
                fill="none"
              >
                <circle opacity="0.5" cx="3" cy="3" r="3" fill="#CACAD9" />
              </svg>
            )}
          </div>
        )}
      </>
    ),
    appendDots: (dots: React.ReactNode) => (
      <div className={styles.customDots}>
        {isDesktopView && (
          <div
            onClick={() => sliderRef?.current?.slickPrev()}
            className="pointer"
          >
            <TGIcon
              icon="arrow-left"
              size="20px"
              fillColor={currentSlide === 0 ? "#CACAD9" : "#684B9B"}
            />
          </div>
        )}
        <ul ref={elementRef}>{dots}</ul>
        {isDesktopView && (
          <div
            onClick={() => sliderRef?.current?.slickNext()}
            className="pointer"
          >
            <TGIcon
              icon="mini-profile-right-arrow"
              size=""
              fillColor={
                Math.ceil(currentSlide) >= totalSlides - slidesToShow
                  ? "#CACAD9"
                  : "#684B9B"
              }
            />
          </div>
        )}
      </div>
    ),
  };

  const customCardComponent = (item: any, cardId: number) => {
    const handleRemoveCard = (
      e: React.MouseEvent<HTMLButtonElement>,
      index: any
    ) => {
      e.stopPropagation();
      let updatedCard = props.formattedSearchFlightData?.filter(
        (x: any, index: any) => x.id !== cardId
      );
      if (!isDesktopView) {
        sliderRef.current?.slickGoTo(0);
      }
      if (props.setFormattedSearchFlightData) {
        props.setFormattedSearchFlightData(updatedCard);
      }

      setLocalStorageData("recentSearchFlight", updatedCard);
    };

    const handleCardSelection = (
      e: React.MouseEvent<HTMLDivElement>,
      cardData: any
    ) => {
      e.stopPropagation();
      if (cardData.type === "Flight Number") {
        props.setSelectedItem("Flight Number");
      } else {
        props.setSelectedItem("Route");
      }

      dispatch(updateFlightSelectedCardData({ cardData: cardData }));
    };
    const handleError = (
      event: React.SyntheticEvent<HTMLImageElement, Event>
    ) => {
      event.currentTarget.src = "../assets/recentsearchdefault.png";
    };

    return (
      <div
        className={`${totalSlides <= 2 && styles.cardStyle}  ${
          totalSlides >= 2 && styles.cardStyleSlider
        } ${
          (totalSlides === 2 || totalSlides === 1) &&
          !isDesktopView &&
          styles["card-width-max"]
        }`}
        onClick={(e) => handleCardSelection(e, item)}
      >
        <div>
          <img
            src={item?.url}
            onError={handleError}
            className={styles.cardSearchImg}
            alt={`recent search card data ${item.id}`}
          />
        </div>
        <div>
          <div>
            {item?.type === "Route" ? (
              <div className={styles["card-airport-details"]}>
                <span>{item?.from?.airportCode}</span>
                <span>
                  <TGIcon icon="plane-icon" size={"16"} fillColor={""} />
                </span>
                <span>{item?.to?.airportCode}</span>
              </div>
            ) : (
              <span>
                {t("label_book_widget_tg")}
                {item?.flightNumber}
              </span>
            )}
          </div>
          <div>
            <div className={styles["flight-status-flex-start"]}>
              {moment(item?.date).format("DD MMM YYYY")}
            </div>
          </div>
          {item.type === "Flight Number" && (
            <div className={styles["flight-status-flex-start"]}>
              <span>{item?.from?.airportCode}</span>
              <span>
                <TGIcon icon="plane-icon" size={"12"} fillColor={"#CACAD9"} />
              </span>
              <span>{item?.to?.airportCode}</span>
            </div>
          )}
        </div>
        <div>
          <button
            onClick={(e) => handleRemoveCard(e, item.id)}
            aria-label="close button"
          >
            <TGIcon icon="x-circle" size={""} fillColor={"none"} />
          </button>
        </div>
      </div>
    );
  };

  //Mobile view card display for two slides scenario items
  const carouselRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchStartX = e.touches[0].clientX;
    carouselRef.current?.setAttribute(
      "data-touch-start",
      touchStartX.toString()
    );
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchEndX = e.changedTouches[0].clientX;
    const touchStartX = parseFloat(
      carouselRef.current?.getAttribute("data-touch-start") || "0"
    );
    const threshold = 50; // Minimum distance for a swipe

    if (touchStartX - touchEndX > threshold) {
      moveToNextSlide();
    } else if (touchEndX - touchStartX > threshold) {
      moveToPrevSlide();
    }
  };

  const moveToNextSlide = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      return Math.min(newIndex, getSlideCount() - 1);
    });
  };

  const moveToPrevSlide = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      return Math.max(newIndex, 0);
    });
  };

  const getSlideCount = () => {
    return carouselRef.current ? carouselRef.current.children.length : 0;
  };

  return (
    <>
      <h4 className={styles.recentSearchHeading}>
        {t("label_book_widget_recent_searches")}
      </h4>
      <div
        className={`${styles["carousel-container"]} ${
          totalSlides < 3 && styles["carousel-flex"]
        }`}
      >
        {isDesktopView && props.formattedSearchFlightData.length > 2 && (
          <Slider ref={sliderRef} {...settings}>
            {reverseSearchData.map((item: any, cardIndex: number) => (
              <>{customCardComponent(item, item.id)}</>
            ))}
          </Slider>
        )}
        {!isDesktopView && props.formattedSearchFlightData.length > 2 && (
          <Slider ref={sliderRef} {...settings}>
            {reverseSearchData.map((item: any, cardIndex: number) => (
              <>{customCardComponent(item, item.id)}</>
            ))}
          </Slider>
        )}
        {!isDesktopView && props.formattedSearchFlightData.length === 2 && (
          <div className={styles["carousel-container"]}>
            <div
              className={styles["carousel"]}
              ref={carouselRef}
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              <div className={styles["carousel-item"]}>
                {customCardComponent(
                  reverseSearchData[0],
                  reverseSearchData[0].id
                )}
              </div>
              <div className={styles["carousel-item"]}>
                {customCardComponent(
                  reverseSearchData[1],
                  reverseSearchData[1].id
                )}
              </div>
            </div>
            <div className={styles["carousel-dots"]}>
              {[1, 2].map((_, index) => (
                <div
                  key={`dots${index}`}
                  onClick={() => setCurrentIndex(index)}
                >
                  {currentIndex === index ? (
                    <svg
                      width="20"
                      height="6"
                      viewBox="0 0 20 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="20" height="6" rx="3" fill="#684B9B" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                    >
                      <circle
                        opacity="0.5"
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#CACAD9"
                      />
                    </svg>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {((isDesktopView && props.formattedSearchFlightData.length <= 2) ||
          (!isDesktopView && props.formattedSearchFlightData.length < 2)) &&
          reverseSearchData.map((item: any, cardIndex: number) => (
            <>{customCardComponent(item, item.id)}</>
          ))}
      </div>
    </>
  );
};

export default FlightStatusRecentSearch;
