import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./flight-status-upcoming-trips.module.scss";
import TGIcon from "../../../../../../shared/tg-icon/index";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import { tripDetails } from "../../../../models/models";
import { RootState } from "../../../../slice/RootReducer";
import moment from "moment";
import {
  tripDataRequest,
  updateTripsPnrDetailsRequest,
} from "../../../../slice/tripSlice";
import { useNavigate } from "react-router-dom";
import Timer from "../../../../../../shared/tg-timer";
import { config } from "../../../../../../config/global";
import { validateDepartDate } from "../../../../../../utils/helper";
import TGButtonVariants from "../../../../../../shared/tg-button-variants";
import TGModal from "../../../../../../shared/tg-modal";
import {
  deleteTripCardDataRequest,
  resetTripCardState,
} from "../../../../slice/deleteTripCardSlice";
import { retrieveJourneyRequest } from "../../../../../../DAPI/src/slice/checkin/checkinSlice";

interface Props {
  page?: string;
  isMiniProfile?: boolean;
}

const FlightStatusUpcomingTrips: React.FC<Props> = ({
  page,
  isMiniProfile,
}) => {
  const sliderRef = useRef<Slider>(null);
  const [trips, setTrips] = useState([]);
  const [showDeleteCard, setShowDeleteCard] = useState(false);
  const [deletePnr, setDeletePnr] = useState("");
  const [recentTrip, setRecentTrips] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tripData: tripDetails = useSelector(
    (state: RootState) => state?.osciReducer?.trips?.upcomingTrips
  );
  const { isLoading } = useSelector((state: RootState) => state?.osciReducer?.trips);

  const { t, i18n } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const totalSlides = trips?.length;
  const screenSize = useScreenSize().deviceSize.width;
  const isDesktopView = screenSize > 767;
  let slidesToShow = screenSize < 768 ? 1.1 : 2.1;
  const elementRef = useRef<any>(null);
  const deleteTripInfo = useSelector(
    (state: RootState) => state?.osciReducer?.deleteTripCard?.upcomingTrips
  );
  const { tripCardStatus } = useSelector(
    (state: RootState) => state?.osciReducer?.deleteTripCard
  );
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: screenSize < 768 ? 1.1 : 2.1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    initialSlide: 0,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex);
    },
    customPaging: (i: number) => (
      <div className={styles.customDot}>
        {elementRef?.current?.childNodes?.length - 1 === i &&
        currentSlide >= totalSlides - slidesToShow ? (
          <svg
            width="20"
            height="6"
            viewBox="0 0 20 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="6" rx="3" fill="#684B9B" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="6"
            viewBox="0 0 6 6"
            fill="none"
          >
            <circle opacity="0.5" cx="3" cy="3" r="3" fill="#CACAD9" />
          </svg>
        )}
      </div>
    ),
    appendDots: (dots: React.ReactNode) => (
      <div className={styles.customDots}>
        {isDesktopView && (
          <div
            onClick={() => sliderRef?.current?.slickPrev()}
            className="pointer"
          >
            <TGIcon
              icon="arrow-left"
              size="20px"
              fillColor={currentSlide === 0 ? "#CACAD9" : "#684B9B"}
            />
          </div>
        )}
        <ul ref={elementRef}>{dots}</ul>
        {isDesktopView && (
          <div
            onClick={() => sliderRef?.current?.slickNext()}
            className="pointer"
          >
            <TGIcon
              icon="mini-profile-right-arrow"
              size=""
              fillColor={
                currentSlide >= totalSlides - slidesToShow
                  ? "#CACAD9"
                  : "#684B9B"
              }
            />
          </div>
        )}
      </div>
    ),
  };

  const monthsStr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formatDate = (d: any) => {
    const month = monthsStr[Number(d?.split("-")?.[1]) - 1];
    const tempDate = `${d?.split("-")?.[0]} ${month} ${d?.split("-")?.[2]}`;
    return moment(tempDate)?.format("DD MMM YYYY, HH:mm");
  };

  useEffect(() => {
    if (tripData && tripData?.pnrDetails?.length > 0) {
      const result: any = tripData?.pnrDetails?.map((x: any, index: number) => {
        return {
          ...x,
          flightDetails: x.flightDetails.map((y: any, index: any) => ({
            ...y,
            image:
              process.env.REACT_APP_TG_IMAGE_SEARCH_URL +
              x?.flightDetails?.[index]?.arrivalCode +
              ".jpg",
          })),
        };
      });

      setTrips(result);
      setRecentTrips(result[0]);
    }
  }, [tripData, dispatch]);

  const renderMultiCityAirportCode = (item: any) => {
    //contains flightRef of connecting flights
    const flight = [];
    //Contains segmentGroups Length
    const flightLength = [];
    let content = "";
    //Contains segmentGroups last flightRef
    let lastArray = [];

    //Iteration for to push elements in an flight array and flightLength array
    for (let i: number = 0; i < item?.segmentGroups.length; i++) {
      flightLength.push(
        JSON.parse(item?.segmentGroups[i].connectionSegments).length + 1
      );
      for (
        let j: number = 0;
        j < JSON.parse(item?.segmentGroups[i]?.connectionSegments).length;
        j++
      ) {
        flight.push(JSON.parse(item?.segmentGroups[i]?.connectionSegments)[j]);
      }
    }

    //Iteration for to push elements in an lastArray
    for (let last: number = 0; last < item?.segmentGroups.length; last++) {
      lastArray.push(
        JSON.parse(item?.segmentGroups[last].connectionSegments).reverse()[0]
      );
    }
    let startVal = 0;
    let count = 0;

    //condition for checking flightDetails flightRef number with flight array elements
    for (let k: number = 0; k < item?.flightDetails.length; k++) {
      if (Number(item?.flightDetails[k]?.flightRef) === flight[count]) {
        if (lastArray[startVal] === Number(item?.flightDetails[k]?.flightRef)) {
          if (
            item?.flightDetails[k]?.flightRef ===
            item?.flightDetails.length.toString()
          ) {
            content += `${item?.flightDetails[k]?.departureCode}-${item?.flightDetails[k]?.arrivalCode}`;
          } else {
            content += `${item?.flightDetails[k]?.departureCode}-${item?.flightDetails[k]?.arrivalCode}//`;
          }
          startVal++;
        } else {
          content += `${item?.flightDetails[k]?.departureCode}-`;
        }

        count++;
      } else {
        if (
          item?.flightDetails[k]?.flightRef ===
          item?.flightDetails.length.toString()
        ) {
          content += `${item?.flightDetails[k]?.departureCode}-${item?.flightDetails[k]?.arrivalCode}`;
        } else {
          content += `${item?.flightDetails[k]?.departureCode}-${item?.flightDetails[k]?.arrivalCode}//`;
        }
      }
    }
    return content;
  };

  const handleViewDetails = () => {
    navigate("/");
  };
  
  const handleCheckIn = (lastName: string, bookingNo: string) => {
    const dapiEnabled = process.env.REACT_APP_DAPI_FLOW || "";
    if (dapiEnabled === 'true') {
      let requestData = {
        pnr: bookingNo,
        lastName: lastName,
      };
      dispatch(retrieveJourneyRequest(requestData));
    }
    else{
      const checkinUrl =
      process.env.REACT_APP_TG_AMADEUS_URL + config.CHECKIN_API;
    const checkinQueryParams = "pnr=" + bookingNo + "&lname=" + lastName;
    const amadeusRedirectionUrl = checkinUrl + "?" + checkinQueryParams;
    window.open(amadeusRedirectionUrl, "_blank");
    }
  };
  
  useEffect(() => {
    if (
      tripCardStatus?.message !== "" &&
      tripCardStatus?.message !== "Trip has been deleted"
    ) {
      dispatch(tripDataRequest());
    }
  }, [tripCardStatus]);

  const deleteHandle = () => {
    setShowDeleteCard(false);
  };

  const showDeletedTripData = (detail: any) => {
    setShowDeleteCard(true);
    setDeletePnr(detail?.pnr);
    setTimeout(() => {
      document.documentElement.style.setProperty(`--html-position`, `unset`);
    }, 10);
  };

  useEffect(() => {
    if (tripCardStatus?.status) {
      const updatedTrips = trips.filter((trip) => trip?.pnr !== deletePnr);
      setTrips(updatedTrips);
      setDeletePnr("");
      dispatch(resetTripCardState());
    }
  }, [tripCardStatus]);
  const deleteTripCardHandler = () => {
    setShowDeleteCard(false);
    dispatch(deleteTripCardDataRequest(deletePnr));
  };

  const customCardComponent = (item: any, cardId: number) => {
    let loading = isLoading && item?.flightDetails?.length === 0;
    return (
      <div
        className={
          !isMiniProfile
            ? `${styles["flight-status-upcoming-trip-card-wrapper"]} card-wrapper`
            : `${styles["mini-profile-card-wrapper"]}`
        }
        style={{
          background: `linear-gradient(10deg, rgba(33, 9, 67, 0.90) 23.72%, rgba(33, 9, 67, 0.00) 116.74%), url(${
            item?.tripType === "MULTI_CITY"
              ? "../assets/multiCity.svg"
              : item?.flightDetails?.[0]?.image
          }) lightgray -1.268px 0px / 117.194% 100% no-repeat`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
        }}
      >
        <div
          className={
            !isMiniProfile
              ? `${styles["flight-status-upcoming-trip-card"]}`
              : `${styles["mini-profile-trip-card"]}`
          }
        >
          {loading ? (
            <div
              className={`${styles["shimmer"]} ${styles["flight-status-upcoming-trip-static-text-shimmer"]}`}
            ></div>
          ) : (
            <div className={styles["flight-status-upcoming-trip-static-text"]}>
              <div>
                {(!loading &&
                  item?.tripType === "MULTI_CITY" &&
                  t("label_trip_multicity") + " " + t("label_trip_to")) ||
                  (!loading &&
                    item?.tripType === "ROUND" &&
                    t("label_trip_round") + " " + t("label_trip_to")) ||
                  (!loading &&
                    item?.tripType === "ONE_WAY" &&
                    t("label_book_widget_one_way") + " " + t("label_trip_to"))}
              </div>
              {!isMiniProfile && item?.addedTrip && (
                <div
                  onClick={() => {
                    showDeletedTripData(item);
                  }}
                  className={styles["close-button"]}
                >
                  <TGIcon icon="mini-profile-close" size="" fillColor="#FFF" />
                </div>
              )}
            </div>
          )}
          {loading ? (
            <div
              className={`${styles["shimmer"]} ${styles["flight-status-upcoming-trip-destination-shimmer"]}`}
            ></div>
          ) : (
            <div className={styles["flight-status-upcoming-trip-destination"]}>
              {!loading && item?.tripType?.toLowerCase()?.includes("multi")
                ? renderMultiCityAirportCode(item)
                : item?.flightDetails?.[0]?.destination}
            </div>
          )}
          {loading ? (
            <div
              className={`${styles["shimmer"]} ${styles["flight-status-upcoming-trip-date-shimmer"]}`}
            ></div>
          ) : (
            <div
              className={
                !isMiniProfile
                  ? `${styles["flight-status-upcoming-trip-date"]}`
                  : `${styles["mini-profile-trip-date"]}`
              }
            >
              {!loading && item?.flightDetails?.length > 1
                ? `${formatDate(
                    item?.flightDetails?.[0]?.depatureDate
                  )} - ${formatDate(
                    item?.flightDetails?.[item?.flightDetails?.length - 1]
                      ?.depatureDate
                  )}`
                : `${formatDate(item?.flightDetails?.[0]?.depatureDate)}`}
            </div>
          )}
          {!isMiniProfile ? (
            <>
              {" "}
              {loading ? (
                <div
                  className={`${styles["shimmer"]} ${styles["flight-status-upcoming-trip-airport-shimmer"]}`}
                ></div>
              ) : (
                <div className={styles["flight-status-upcoming-trip-info"]}>
                  {!loading &&
                    item?.flightDetails?.[item?.flightDetails?.length - 1]
                      ?.arrivalAirportName}
                </div>
              )}
              <div className={styles["flight-status-upcoming-trip-info"]}>
                {t("label_booking_ref")}: {item?.pnr}
              </div>
            </>
          ) : (
            <>
              {loading ? (
                <div
                  className={`${styles["shimmer"]} ${styles["iconContainer-shimmer"]}`}
                ></div>
              ) : (
                <div
                  className={styles.iconContainer}
                  onClick={handleViewDetails}
                >
                  <span className={styles.buttonText}>
                    {t("label_view_booking")}
                  </span>
                  <img
                    src={"../assets/arrow-right-icon.png"}
                    alt="View Details"
                  />
                </div>
              )}
            </>
          )}
        </div>
        {!isMiniProfile && (
          <Timer
            flightData={item}
            view={handleViewDetails}
            checkin={handleCheckIn}
            isTimer={false}
            page={page}
            isMyTrips={false}
          />
        )}
      </div>
    );
  };
  const deleteTrip = () => (
    <TGModal
      show={showDeleteCard}
      handleClose={deleteHandle}
      centered={true}
      customModalClass={styles["trip-card-model"]}
      customOffCanvasClass={styles["trip-card-offcanvas"]}
      backdropClassName={styles["trip-card-backdrop"]}
    >
      <div className={styles.tcModalFlex}>
        <div className={styles.tcModal}>
          <img
            className={styles.deletetripimage}
            src="../assets/DeleteTripImage.png"
            alt="deletetripimage"
          />
          <div className={styles.modalContent}>
            <span className={styles.modalHeading}>
              {t("delete_trip_heading")}
            </span>
            <div className={styles.modalDescription}>
              {t("delete_trip_description")}
            </div>
          </div>
        </div>
        <div className={styles.dbuttonContainer}>
          <TGButtonVariants
            label={t("button_label_keep")}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="30px"
            boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
            bgColor="#F5EFFF"
            bgColorHover="#F5EFFF"
            padding="8px 24px"
            textColor="#381B6B"
            fontFamily="Inter"
            fontSize="16px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="160%"
            height="50px"
            onClick={deleteHandle}
            borderHover="none"
            width={isDesktopView ? "128px" : "50%"}
          />
          <TGButtonVariants
            label={t("button_label_delete")}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="30px"
            boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
            bgColor="#381B6B"
            bgColorHover="#381B6B"
            padding="8px 24px"
            textColor="#FFFFFF"
            fontFamily="Inter"
            fontSize="16px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="160%"
            height="50px"
            onClick={deleteTripCardHandler}
            borderHover="none"
            width={isDesktopView ? "170px" : "50%"}
          />
        </div>
      </div>
    </TGModal>
  );
  const handleLazyLoad = (cardId: number) => {
    const pnr = trips[cardId]?.pnr;
    const lastName = trips[cardId]?.lastName;
    const pastTrip = false;
    dispatch(updateTripsPnrDetailsRequest({ pnr, lastName, pastTrip, cardId }));
  };

  useEffect(() => {
    if (
      tripData?.pnrDetails[
        isDesktopView ? Math.round(currentSlide) + 1 : Math.round(currentSlide)
      ]?.flightDetails.length === 0
    ) {
      handleLazyLoad(
        isDesktopView ? Math.round(currentSlide) + 1 : Math.round(currentSlide)
      );
    }
  }, [currentSlide]);

  return (
    <>
      {!isMiniProfile && page === "FS" && (
        <div className={styles["flight-status-upcoming-trip-header"]}>
          {t("label_upcomingtrips_header")}
        </div>
      )}
      {deleteTrip()}
      <div
        className={
          !isMiniProfile
            ? `${styles["carousel-container"]} ${
                totalSlides < 3 && styles["carousel-flex"]
              }`
            : `${styles["min-profile"]}`
        }
      >
        {!isMiniProfile ? (
          (isDesktopView && trips?.length > 2) ||
          (!isDesktopView && trips?.length > 1) ? (
            <Slider ref={sliderRef} {...settings}>
              {trips.map((item: any, cardIndex: number) => (
                <>{customCardComponent(item, cardIndex)}</>
              ))}
            </Slider>
          ) : (
            trips.map((item: any, cardIndex: number) => (
              <>{customCardComponent(item, cardIndex)}</>
            ))
          )
        ) : (
          <>{customCardComponent(recentTrip, 0)}</>
        )}
      </div>
    </>
  );
};

export default FlightStatusUpcomingTrips;
